<template>
  <div class="home">
    <p class="aligncenter">
      <img alt="centered image" src="../assets/logo.jpg" />
    </p>
    <FrontPage msg="Welcome to chicken plan" />
  </div>
</template>

<script>
// @ is an alias to /src
import FrontPage from "@/components/FrontPage.vue";

export default {
  name: "Home",
  components: {
    FrontPage,
  },
};
</script>

<style>
.aligncenter {
  text-align: center;
}
</style>
